<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <template v-slot:activator="{ on, attr }">
      <v-btn v-bind="attr" v-on="on" icon>
        <v-icon>mdi-filter-variant</v-icon>
      </v-btn>
    </template>

    <v-card tile>
      <v-app-bar color="primary" dark>
        <v-btn dark icon @click.native="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer/>
        <v-btn dark icon @click="clearFilter">
          <v-icon>mdi-filter-variant-remove</v-icon>
        </v-btn>
      </v-app-bar>

      <kurcc-filter-filters :key="filterComponentKey" :filter.sync="filter" class="pb-16"/>

      <v-btn :style="{'z-index': '200'}" :width="'210px'" bottom class="center-x" color="primary" elevation="2" fixed
             @click="applyFilter">
        {{ $vuetify.lang.t('$vuetify.general.filter.applyFilter') }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { RESET_FILTER, SET_FILTER } from '@/modules/app/store/mutation-types'

export default {
  name: 'KurccFilter',
  components: {
    KurccFilterFilters: () => import('@/modules/app/components/KurccFilterFilters')
  },
  data () {
    return {
      dialog: false,
      filter: null,
      filterComponentKey: 0
    }
  },
  watch: {
    $route (to, from) {
      if (from.name !== to.name) {
        this.refreshFilters()
      }
    }
  },
  methods: {
    applyFilter () {
      this.$store.commit(SET_FILTER, this.$lodash.cloneDeep(this.filter))
      this.closeDialog()
    },
    clearFilter () {
      this.$store.commit(RESET_FILTER)
      this.refreshFilters()
      this.closeDialog()
    },
    closeDialog () {
      this.dialog = false
    },
    cloneFilter () {
      this.filter = this.$lodash.cloneDeep(this.$store.state.filter)
    },
    refreshFilters () {
      this.cloneFilter()
      this.filterComponentKey++
    }
  },
  created () {
    this.cloneFilter()
  }
}
</script>
